.active {
  display: flex;
  justify-content: center;

  &__title {
    margin-bottom: 100px;
  }

  &-people__item {
    width: 100%;

    & img {
      object-fit: cover;
      object-position: top;

      @media (max-width: 767px) {
        height: 600px;
      }
    }

    @media (max-width: 480px) {
      width: 100%;

      & img {
        width: 100%;
      }
    }

    & img {
      width: 100%;
      object-fit: cover;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    &:hover img {
      opacity: 1;
    }

    &:hover .active-people__info {
      display: flex;
      animation: fadeIn 0.3s forwards;
    }
  }

  &-people__info {
    position: absolute;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      360deg,
      rgba(52, 116, 188, 1) 5%,
      rgba(255, 255, 255, 0.101) 95%
    );
    color: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 0 20px 20px;
    transition: background 0.3s ease-in-out;

    & p {
      font-size: 15px;
      font-weight: normal;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: 480px) {
  .active-people__inner {
    width: 100%;
  }
}
