.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000000;
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow-y: auto;

  &__content {
    padding: 120px 0 0;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  & div {
    width: 90%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 100px;
  }

  &__list {
    list-style: none;
    max-width: 300px;
    width: 100%;
  }

  &__label {
    color: #da251c;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__link {
    color: #74767b;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    gap: 5px;
    line-height: 2;
  }
}

.navbar__logos {
  width: 300px;

  & img {
    object-fit: cover;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .navbar {
    &__content {
      padding: 100px 15px;
    }

    & div {
      gap: 50px;
    }
  }
}

@media (max-width: 992px) {
  .navbar {
    &__content {
      padding: 80px 15px;
    }

    & div {
      gap: 30px;
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    &__content {
      padding: 60px 15px;
    }

    &__label {
      font-size: 20px;
    }

    &__link {
      font-size: 16px;
    }

    & div {
      gap: 20px;
    }

    .navbar__logos {
      width: 250px;
      margin-top: 30px;
    }
  }
}

@media (max-width: 576px) {
  .navbar {
    &__content {
      padding: 40px 15px;
    }

    &__label {
      font-size: 18px;
    }

    &__link {
      font-size: 14px;
    }

    & div {
      gap: 10px;
    }

    .navbar__logos {
      width: 200px;
    }
  }
}
