.slider {
  padding: 100px 15px;
  padding-bottom: 50px;
  position: relative;
}

.more-link {
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
}

.text-content__title {
  font-size: 20px;
  color: #74767b;
  margin-bottom: 10px;
}

.text-content {
  margin-left: 20px;
}

.right-col {
  & p {
    font-size: 16px;
  }
}

.slider-container {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 50px;
  position: relative;

  .main-content {
    flex: 2;
    display: flex;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 550px;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: 0.5s;

      @media (max-width: 678px) {
        height: 339px;
      }

      & figure {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background: #fff;
        overflow: hidden;

        &:hover img {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }

    .image {
      width: 100%;
      object-fit: cover;
      height: 100%;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }

    @media (min-width: 1100px) {
      .text-content {
        width: 900px;
      }
    }

    .text-content {
      display: flex;
      flex-direction: column;

      p {
        font-size: 16px;
        text-align: justify;
        color: #74767b;
      }

      .more-link {
        color: #3474bc;
        cursor: pointer;
        margin-top: 10px;
        margin-top: auto;
      }
    }
  }

  .sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    max-height: 450px;
    margin-left: 100px;

    .thumbnail {
      margin-bottom: 15px;
      cursor: pointer;
      transition: transform 0.3s ease;
      opacity: 0.6;

      img {
        width: 100px;
        height: 70px;
        object-fit: cover;
        border: 2px solid transparent;
        transition: border 0.3s ease;

        &.active {
          border: 2px solid #3474bc;
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }

    .right-col {
      display: flex;
      gap: 10px;
      align-items: center;
      height: 100px;
    }
  }
}

@media (max-width: 1190px) {
  .sidebar,
  .right-col {
    & img {
      display: none;
    }
  }
}

@media (max-width: 1416px) {
  .right-col {
    & h2 {
      display: none;
    }
  }
}

@media (max-width: 1100px) {
  .main-content {
    flex-direction: column;
  }

  .text-content {
    margin-top: 20px;
    margin-left: 0;
  }

  .full-program-button {
    bottom: -60px !important;
  }

  .sidebar {
    display: none !important;
  }

  .more-link {
    margin-top: 20px !important;
  }
}

@media (max-width: 992px) {
  .text-content__title {
    font-size: 20px;
  }
}
