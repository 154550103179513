.swiper {
  width: 100%;
  max-height: 85vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.slider {
  width: 100%;
  max-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.slider-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider video {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #000;
}

.swiper {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &__position {
    position: absolute;
    top: 0;
    padding: 100px 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    background: rgb(52, 116, 188);
    background: linear-gradient(
      360deg,
      rgba(52, 116, 188, 1) 8%,
      rgba(52, 116, 188, 0.10136554621848737) 40%
    );

    & h1 {
      color: white;
      font-size: 36px;
      font-weight: 700;
      @media (max-width: 992px) {
        font-size: 26px;
      }
      @media (max-width: 786px) {
        font-size: 20px;
      }
      @media (max-width: 678px) {
        font-size: 18px;
      }
    }
  }
}
