.home__content {
  position: absolute;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.46262254901960786) 0%,
    rgba(0, 0, 0, 0.35898109243697474) 100%
  );
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 999;
}

.modal {
  position: absolute;
  top: 25%;
  left: auto;
  padding: 20px;
  max-width: 700px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  z-index: 1000000;

  @media (max-width: 1200px) {
    min-width: 800px;
  }

  @media (max-width: 830px) {
    width: 80%;
    min-width: unset;
  }

  @media (max-width: 786px) {
    top: 14%;
  }

  & .social {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & .social-icons {
      display: flex;
      justify-content: center;
      gap: 5px;
    }
  }

  &__cls {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    cursor: pointer;
    background-color: transparent;
    border: 0;

    & svg {
      font-size: 30px;
      color: #444;
    }
  }

  &__notification {
    margin-top: -60px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    background: #3474bc;
  }

  &__title {
    color: #212529;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__text {
    color: #74767b;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    padding: 0 15px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__btn {
    background: #3474bc;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    line-height: 45px;
    border-radius: 6px;

    & svg {
      margin-right: 10px;
    }

    &:last-child {
      background: #ff6464;
      color: #ffffff;
    }
  }
}
