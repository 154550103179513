.breadcrumbs {
  margin: 10px 0;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;

    & span {
      @media (max-width: 678px) {
        font-size: 14px;
      }
    }
  }

  .separator {
    margin: 5px;
  }

  a {
    text-decoration: none;
    color: #3474bc;
    font-size: 18px;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 678px) {
      font-size: 14px;
    }
  }
}
