.X {
  height: 500px;
  max-width: 500px;
  width: 100%;

  .twitter-timeline {
    width: 100%;
    min-width: 500px;
    height: 500px;
  }
}
