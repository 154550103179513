.react-tabs__tab {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.react-tabs__tab--selected {
  background: transparent !important;
  color: #fff !important;
}
.react-tabs__tab--selected:focus:after {
  outline: none !important;
  border: 0 !important;
}
.react-tabs__tab:focus:after {
  background: transparent !important;
}
