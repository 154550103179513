.mw1800 {
  margin: 0 auto;
}
.iframe {
  margin: 100px auto;
  @media (max-width: 1200px) {
    max-height: none;
    margin: 50px auto;
  }
  &__title {
    margin-bottom: 100px;
  }
  &__facebook {
    max-width: 500px;
    width: 100%;
    & iframe {
      width: 100%;
    }
  }
  .iframeBlocks {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .iframeBlock {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 1200px) {
      align-items: center;
    }
    .iframes {
      height: 500px;
    }
    .iframeText {
      font-size: 26px;
      font-weight: bold;
      color: #3474bc;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 1600px) {
  .iframes {
    width: 100%;
    height: 500px;
  }
}
