* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

html,
body {
  overscroll-behavior: contain;
}

a {
  text-decoration: none;
}

footer {
  margin-top: auto;
}

.custom-html-content {
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  color: #74767b;

  & p img {
    width: 80%;
    max-height: 400px;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
  }
}
