.news {
  margin-top: 50px;
  padding-left: 15px;
  padding-right: 15px;

  .main-fz {
    font-size: 20px !important;
  }

  &-title {
    display: flex;
    margin-top: 30px;
  }

  &-filter {
    margin: 50px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 1;
    position: relative;

    &__dropdowns {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media (max-width: 650px) {
        width: 100%;
        gap: 10px;
      }
    }

    &__form {
      width: 186px;
      height: 41.6px;
      display: flex;
      border-radius: 6px;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 2px solid #74767b;

      @media (max-width: 650px) {
        width: 100%;
      }

      & input {
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        outline: none;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 400;
        color: #74767b;
        border-radius: 6px;
      }

      & button {
        border: none;
        border-radius: 6px;
        height: 100%;
        background: none;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #74767b;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;

    &__items {
      position: relative;
      width: 33.3%;
      height: 660px;
      box-shadow: 0px 0px 8px 1px rgb(220, 220, 220);
      display: flex;
      animation-duration: 1.2s;
      flex-direction: column;
      border-radius: 10px;

      & a {
        width: 100%;
        height: 100%;
        text-decoration: none;
      }

      & figure {
        width: 100%;
        max-height: 333px;
        height: 100%;
        margin: 0;
        padding: 0;
        background: #fff;
        overflow: hidden;
        position: relative;
      }

      & figure img {
        object-fit: cover;
        width: 100%;
        object-position: top;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 100%;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
      }

      & figure:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }

      .body {
        padding: 10px 20px;
        height: 47%;
        display: flex;
        flex-direction: column;

        & div {
          margin-top: 10px;

          & p {
            font-size: 18px;
            text-align: justify;
            color: #74767b;

            @media (max-width: 1000px) {
              font-size: 14px;
            }

            @media (max-width: 650px) {
              font-size: 16px;
            }
          }
        }

        h3 {
          margin-top: 15px;
          color: #74767b;
          font-size: 18px;
          font-weight: bold;
          max-height: 80px;
          height: 100%;

          @media (max-width: 1000px) {
            font-size: 18px;
          }

          @media (max-width: 650px) {
            font-size: 18px;
          }
        }

        & .body-text {
          display: flex;
          flex-direction: column;
          max-height: 120px;
          height: 100%;
          align-items: flex-start;

          & p {
            font-size: 18px;
            text-align: justify;
            color: #74767b;

            @media (max-width: 1000px) {
              font-size: 14px;
            }

            @media (max-width: 650px) {
              font-size: 16px;
            }
          }
        }

        & .calendar {
          color: #74767b;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;

          & svg {
            font-size: 26px;
            color: #74767b;
            margin-right: 5px;
          }

          @media (max-width: 1000px) {
            font-size: 16px;
          }

          @media (max-width: 650px) {
            font-size: 16px;
          }
        }

        & a {
          color: #3474bc;
          font-size: 18px;
          font-weight: 500;
          text-decoration: none;
          display: flex;
          margin-top: auto;
          margin-bottom: 5px;
          align-items: center;
          gap: 5px;
          transition: 0.5s;

          @media (max-width: 1000px) {
            font-size: 14px;
          }

          @media (max-width: 650px) {
            font-size: 16px;
          }

          &:hover {
            color: #da251c;
          }
        }

        & p {
          display: flex;
          align-items: center;
          color: #74767b;

          & svg {
            margin-right: 10px;
          }
        }
      }
    }
  }

  & .news-gallery {
    display: flex;
    margin-top: 100px;

    & .up {
      width: 50%;
      display: flex;
      gap: 25px;

      &__items {
        width: 100%;
        position: relative;

        & article {
          overflow: hidden;
          border-radius: 10px;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 10px;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(
              to top,
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0)
            );
            content: "";
            width: 100%;
            height: 100%;
            z-index: 10;
          }

          &:hover .desc-item {
            display: block;
          }

          &:hover .content__img img {
            transform: scale(1.1);
          }

          & .content {
            height: 285px;
            font-size: 45px;
            position: relative;
            border-radius: 10px;

            &__img {
              overflow: hidden;

              &::after {
                content: "";
                display: block;
                padding-bottom: 56.5%;
              }
            }

            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
              transition: 0.3s ease-in-out;
            }

            &__info {
              transition: 1s ease-in-out;
              font-size: 1rem;
              position: absolute;
              align-items: flex-end;
              justify-content: flex-start;
              display: flex;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              padding: 0;

              &-item {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                background-color: transparent;
                background-image: linear-gradient(
                  180deg,
                  rgba(242, 66, 66, 0) 0,
                  #f24242 100%
                );
                max-width: 100%;
                padding: 20px;
                position: relative;
                width: 100%;
                z-index: 35;

                &__body {
                  & .title {
                    font-size: 1.3em;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 0 !important;
                    line-height: 1.3;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    color: #eee !important;

                    &__body {
                      width: 100%;

                      &-mark {
                        margin-bottom: calc(5px + 0.3em);

                        & span {
                          flex-wrap: wrap;
                          margin: -6px -4px;
                          display: flex;
                          align-items: center;
                          flex-wrap: nowrap;
                          max-width: 100%;

                          & a {
                            background-color: #f24242;
                            color: #fff !important;
                            font-weight: 700;
                            letter-spacing: 0.09em;
                            text-transform: uppercase;
                            font-size: 10px;
                            margin: 6px 4px;
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            box-shadow: none;
                            text-decoration: none;
                            padding: 5px 8px;
                            line-height: 1 !important;
                            position: relative;
                            border-radius: 4px;
                          }
                        }
                      }

                      &-title {
                        font-size: 15px;
                        font-weight: 700;
                        margin: 0;
                        padding: 0;
                        margin-bottom: 0 !important;
                        line-height: 1.3;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: start;

                        & a {
                          color: #eee;

                          &:hover {
                            color: #fff;
                          }
                        }
                      }

                      &-date {
                        opacity: 0.8;
                        font-size: 0.8em;
                        line-height: 1.3;

                        & span {
                          margin: -6px -4px;
                          display: flex;
                          align-items: center;
                          flex-wrap: nowrap;
                          max-width: 100%;

                          & a {
                            color: #eee;

                            &:hover {
                              color: #fff;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .desc-item {
            font-size: 1rem;
            display: none;
            color: #fff;

            & span {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              visibility: visible;
              margin-top: 10px;
            }
          }
        }
      }
    }

    & .down {
      padding-left: 5px;
      flex-wrap: wrap;

      &* {
        max-width: 100%;
      }

      width: 50%;
      display: flex;
      gap: 5px;
      margin-top: 5px;

      & .up__items {
        width: 48%;
        flex: 0 0 auto;
      }
    }
  }
}

.news__link-block{
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.news__link {
  padding: 15px 50px;
  color: #74767b;
  border: 1px solid #74767b;
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  transition: 0.5s;

  &:hover {
    background: #007bff;
    color: #fff;
    border: none;
  }
}

.news__views {
  margin: 10px 0;
  display: flex;
  align-items: center;

  & svg {
    margin-left: 5px;
  }
}

@media (min-width: 2090px) {
  .news-content__items {
    min-width: 20%;
  }
}

@media (min-width: 1920px) {
  .news-content__items {
    width: 32%;
  }
}

@media (max-width: 1910px) {
  .news-content__items {
    width: 32%;
  }
}

@media (max-width: 1610px) {
  .news-content__items {
    width: 32%;
  }

  .news-full {
    display: flex;
    flex-direction: column;

    & div:first-child {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1200px) {
  .news-content__items {
    width: 48%;
  }
}

@media (max-width: 1000px) {
  .news-content {
    justify-content: center;
  }

  .news-content__items {
    width: 48%;
  }
}

@media (max-width: 786px) {
  .news-content {
    justify-content: center;
  }

  .news-content__items {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .news-full {
    width: 100%;
  }

  .news-content {
    justify-content: center;
  }

  .news-content__items {
    width: 100%;
  }

  .news-filter {
    flex-direction: column;
  }

  .news-filter__form {
    margin-top: 10px;
  }
}

.gap {
  gap: 25px !important;
}

.marginTop50 {
  margin-top: 50px;
}

.marginTop250 {
  margin-top: 250px;

  @media (max-width: 1600px) {
    margin-top: 80px;
  }

  @media (max-width: 992px) {
    margin-top: 50px;
  }
}
