.subscribe {
  padding-bottom: 20px;

  &__form {
    max-width: 480px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  & div {
    margin-top: 10px;

    & p {
      color: #da251c;
      font-size: 18px;
    }
  }

  &__input {
    padding: 10px 20px;
    font-size: 16px;
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px 0 0 5px;
    &:focus {
      border: 2px solid #da251c;
      outline: none !important;
    }
  }

  &__button {
    background: #da251c;
    border: none;
    color: #fff;
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    border-radius: 0 5px 5px 0;
  }
}

.subscribe p[data-testid="success-message"] {
  color: blue;
}

@media (max-width: 786px) {
  .subscribe {
    width: 100%;
  }
  .subscribe__form {
    flex-direction: column;
    max-width: 786px;
    width: 100%;
  }
  .subscribe__input {
    width: 100%;
  }
  .subscribe__button {
    width: 100%;
  }
}
