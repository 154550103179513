.gallery {
  padding-top: 0px !important;
  padding: 100px 15px;

  &__container {
    padding: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
    gap: 30px;
  }

  &__left,
  &__right {
    width: 48%;
    border-radius: 10px;
    box-shadow: 0px 0px 13px 4px rgba(34, 60, 80, 0.3);
  }

  &__items {
    position: relative;

    & img {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }

    & iframe {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }

  &__right {
    &:hover .gallery__bg-right {
      display: none;
      transition: 0.4s;
    }
  }

  &__bg {
    &-left {
      position: absolute;
      left: 0;
      width: 100%;
      height: 30%;
      bottom: 0;
      z-index: 2;
      display: block;
      background: linear-gradient(
        180deg,
        rgba(52, 116, 188, 0) 0%,
        rgba(52, 116, 188, 1) 100%
      );
      transition: 0.5s;

      & div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        color: #fff;

        & p {
          font-size: 20px;
          font-weight: bold;

          @media (max-width: 992px) {
            font-size: 18px;
          }
        }
      }
    }

    &-right {
      position: absolute;
      left: 0;
      width: 100%;
      height: 30%;
      bottom: 0;
      z-index: 2;
      display: block;
      transition: 0.4s;
      background: linear-gradient(
        180deg,
        rgba(52, 116, 188, 0) 0%,
        rgb(255, 87, 87) 100%
      );

      & div {
        padding: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: #fff;

        & p {
          font-size: 20px;
          font-weight: bold;

          @media (max-width: 992px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .gallery__content {
    flex-direction: column;
  }

  .gallery__right {
    width: 100%;
  }

  .gallery__left {
    width: 100%;
  }

  .swiper-slide {
    width: 100% !important;
  }
}
