.party {
  background: #3474bc;
  padding: 30px 0;
  min-height: 100%;
  display: flex;
  align-items: center;

  &-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &-items {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 10px 25px;
    border-radius: 8px;
    box-shadow: 0px 0px 34px 0px rgba(34, 60, 80, 0.3);
    white-space: nowrap;
    flex-shrink: 0;

    & p {
      white-space: nowrap;

      & svg {
        margin-right: 10px;
      }
    }

    & p:first-child {
      font-size: 70px;
    }

    & p:last-child {
      font-size: 20px;
      font-weight: bold;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px;
    max-width: 300px;
    margin: auto;

    & p {
      white-space: nowrap;
    }

    & p:first-child {
      font-size: 50px;
    }

    & p:last-child {
      font-size: 16px;
      margin-top: -10px;
      margin-left: 10px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  .swiper-container {
    flex-grow: 1;
    max-width: 800px;
  }

  @media (max-width: 992px) {
    .party-content {
      flex-direction: column;
      align-items: center;
      white-space: normal;
    }

    .party-swiper {
      display: flex;
      padding: 10px 0;
      width: 100%;
    }
  }
}
