.MapSvg {
  width: 100%;
  height: 100%;

  & path {
    fill: #3474bc;
    &.active {
      fill: #da251c;
    }
    &:hover {
      fill: #f5625a;
      cursor: pointer;
    }
  }
}
