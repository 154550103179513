.header-top {
  background: #f5f5f5;
  padding: 7px 0;

  &__warning {
    display: flex;
    align-items: center;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    animation: scroll-text 5s linear infinite;

    & svg {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  &__content {
    color: #da251c;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      text-wrap: nowrap !important;
    }

    & a {
      text-wrap: nowrap !important;
    }
  }

  &__link {
    color: #da251c;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;

    & svg {
      margin-right: 5px;
    }
  }
}

@keyframes scroll-text {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media (max-width: 800px) {
  .header-top__content {
    flex-direction: column;

    & a {
      margin: 10px 0;
      font-size: 16px;
    }
  }
}

@media (max-width: 678px) {
  .header-top__content {
    & a {
      font-size: 14px;
    }
  }
}
