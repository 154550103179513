.container {
  width: 100%;
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;

  // @media (max-width: 1200px) {
  //   max-width: 80vw;
  // }

  // @media (max-width: 960px) {
  //   max-width: 80vw;
  // }

  // @media (max-width: 720px) {
  //   max-width: 80vw;
  // }
}
