.statistic {
  background: #d50000;
  padding: 50px;
  margin-top: 30px;

  &-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 0;
    justify-content: space-between;

    @media (max-width: 700px) {
      justify-content: center;
    }
  }

  &-item {
    width: 300px;
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-icon {
    color: #fff;

    & img {
      width: 100px;
      height: 100px;
    }
  }

  &-num {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
  }

  &-title {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    margin-top: auto;
  }
}

@media (max-width: 992px) {
}

.items {
  position: relative;
}
